import { ORGBRAIN_VERSION } from '../orgbrain_version';

export const sharedenvironment = {
  ORGBRAIN_VERSION,
  sessionDataLabel: 'orgbrainSession',
  debug: false,
  homeApp: 'home',
  jitsiMeetUrl: 'meet.orgbrain.ai',
  orgbrain: true,
  HTTP_TIMEOUT: 51234,
  HTTP_TIMEOUT_LONG: 10 * 60 * 1000,
  helpUrl: 'https://support.orgbrain.ai',
  bankid2FA: false,
  adminPath: '/admin',
  shareholdersPath: '/shareholders',
  newsPath: '/communication',
  boardmatchCandidatePath: '/boardmatch-candidate',
  boardmatchOrgPath: '/boardmatch-org',
  filesPath: '/files',
  investorPath: '/investments',
  versionUpdateCheckPeriod: 60000,
  SERVER_VERSION_CHECK: true,
  //  check for activity bebouncing to avoid to many notifications.
  ip2comURL: 'https://api.ip2loc.com/0aBK3WbrqmVvrpLrl4WOptLVyGBIs1ej/',
  GET_RID_OF_ALL_THE_RUBISH_CRETED_BY_FRONTEND_ERRORS_THAT_MAKES_DEBUGING_IMPOSSIBLE: false,
  defaultLang: '',

  loguncaughtErrors: true,
  EMAIL_QUERY_SECRET: 'Ploutonion',
  ENABLE_STRESS_SERVICE: false,
  showbankIdLogon: true,
  NO_SPLASH_TEST: false,
  SPLASH_DEBOUNCE: 1000 * 20,
  errors: { period: 1000 * 60, nEvent: 10 },
  debugChat: false,
  defaultRequestTimeout: 10000,
};
