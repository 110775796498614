import {
  NotificationClusterTypeEnum,
  NotificationCodeEnum,
  NotificationStatus,
} from './bellNotificationCodes';

export type OrginfoForBatchEmail = { [organizatioId: string]: { name: string } };

export interface INotification {
  _id?: any;
  id?: any;
  due?: Date; //  time stamp for when task is due (optional).

  userId: any; // userId of who we are notifying
  code: NotificationCodeEnum; //   Unique code for this Notifiction type -- used for language templates -- decoding data (this is also in payload)
  data: Partial<ParamsForBellTemplate>; //     Used to be stringified  artbirtary data specific to  code   [ DEPRECATE THIS AND USE PAYLOAD]

  status?: NotificationStatus; // DEPRECTE THIS AND USE THE FLAGS

  activateBefore?: number;
  expireAfter?: number;
  deleted?: boolean;
  // OPTIONAL KEYS FOR SEARCHING  (keep adding as needed)
  organizationId?: any; //  organization context
  meetingId?: any; // meeting context
  direntId?: any; //  for file operations.
  signOrderId?: any; // sign orders  signOrderSchema.orderId
  requestId?: string; // signicat signingId   //  criipto
  taskId?: string; // individual taskId   (we generate for signicat.   Get formn crypto signatoryId)
  createdAt?: Date;
  updatedAt?: Date;

  // FLAGS
  batchVersion?: number; //  version of this notification
  bellRead?: boolean;
  doneFlag?: boolean;
  emailSent?: boolean;
  doBatch?: boolean; // This is a flag to indicate that this notification should be batched and has not been sent yet.
  seen?: boolean; // User has seen this notification
  channelId?: string; //  channel id for chat notifications
  messageId?: string; //  message id for chat notifications
  // PAYLOAD
  // payload: CombinedNotificationPayload; // We do not want to index this field.

  // Tempory field for frontend to keep track of merged items
  list?: INotification[]; //  This is a list of notifications that are batched together.

  // expiresAt?: Date; //  time stamp after which we auto delete this notification
}

// // These guys can be used as keys  in the database.
export class Notifications {
  static notificationIndices = {
    organizationId: true,
    meetingId: true,
    direntId: true,
    signOrderId: true,
    requestId: true,
    taskId: true,
    userId: true,
    email: true,
    code: true,
    signicatOrderId: true,
  };

  // Thes are not keys so can be stored in a data object.
  // This filters out all the crap that seems to have accumulated.
  // We need all the fileds that must be persisted to recreate a notifiction in here.
  static allowedFields = {};
}

export enum DistributionMediums {
  EMAIL = 'EMAIL',
  EMAIL_WITH_ATTACHMENT = 'EMAIL_WITH_ATTACHMENT',
  SMS = 'SMS',
  BELL = 'BELL',
  PUSH = 'PUSH',
  BATCH = 'BATCH', //  end as batch email only ( no full payload for email)
}

export const requiredFields = {};
requiredFields[DistributionMediums.EMAIL] = {
  lang: true,
  emailType: true,
  organizationId: true,
  recipient: { email: true },
  sender: { email: true },
};

requiredFields[DistributionMediums.EMAIL_WITH_ATTACHMENT] = {
  lang: true,
  emailType: true,
  organizationId: true,
  recipient: { email: true },
  sender: { email: true },
};

requiredFields[DistributionMediums.BELL] = {
  recipient: { userId: true },
};

requiredFields[DistributionMediums.SMS] = {
  phone: true,
  message: true,
};

requiredFields[DistributionMediums.PUSH] = {
  recipient: { userId: true },
  url: true,
  webPushPayload: {
    message: true,
    title: true,
  },
};

//This class is a simplifed version of the optional fields in sharednotificationpayload type, its okay to null any of these values
export type NotificationPayloadVariables = {
  //for finding channel specific notifs, like messages
  channelId: string;
  //For finding specific message notifs
  messageId: string;
  //points to a direntfolder that will be attached to an email
  direntId?: string;
};

// Various consumers require different forms of this so all optional
export class NotificationUser {
  _id?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  userId?: string;

  static create(user: {
    email?: string;
    firstName?: string;
    lastName?: string;
    _id?: any;
    userId?: string;
  }): NotificationUser {
    return {
      _id: user.userId || (user._id && user._id.toString()),
      userId: user.userId || (user._id && user._id.toString()),
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
    };
  }
}

export class EmailInfo {
  recipient?: NotificationUser;
  entityName?: string;
  url?: string;
  sender?: NotificationUser;
  lang?: string;
  emailType?: EmailTypeEnum;
  orgId?: any;
  client?: any;
  emailCode?: string;
  meetingTitle?: string;
  meetingType?: string;
  userInput?: string;
  time?: string;
  endTime?: string;
  location?: string;
  subject?: string;
  textArray?: string[];
  orgName?: string;
  paramsForGenericEmailTemplate?: ParamsForCustomEmailTemplate;
  reminderSubject?: any;
  procedureType?: string;
  preVotingEnabled?: boolean;
  proxyInstructions?: string;
  isReminder?: boolean;
  emailWithAttachmentPayload?: EmailWithAttachmentPayload;
}
//Why is this even called "WithAttachement", this is very specifically for meeting invites and barly any fileattachements
export type EmailWithAttachmentPayload = {
  requesterOrganizationId: string;
  displayedOrganizationId: string;
  meetingId: string;
  timestamp: number;
  emailSubject: string;
  finalOrgName: string;
  icsAlarmDescription: string;
  procedureType: string;
  preVotingEnabled?: boolean;
  proxyInstructions?: string;
  icsMainDescription: string;
  locale: string;
  userInputText: string;
  emailAddressFrom: string;
  recipientUserIds: string[];
  meetingUrl: string;
  meetingType: string;
  arbitratyDate?: string;
  arbitratyDuration?: number;
  arbitratyAdditionalUserInput?: any[];
  direntId?: string;
  isReminder?: boolean;
};

export type GenericEmailWithAttachment = {
  //Points to a folder which should have all its files in the email
  direntId: string;

  subject: string;
  mainHeadline: string;
  firstLineInBold: string;
  bodyTexts: string[];
  callToAction: string;
  actionButtonText: string;
  actionButtonUrl: string;
};

// Recipient data often a list
// This is merged into the shared payload to form the full payload
export interface RecipientPayload extends EmailInfo {
  recipient?: NotificationUser; //  email
  due?: Date;
  url?: string;
  paramsForGenericEmailTemplate?: ParamsForCustomEmailTemplate;
  paramsForBellTemplate?: Partial<ParamsForBellTemplate>;
  webPushPayload?: PushEventPayload;
  taskId?: string;
}

// Essential fields for shared notifications in here
export interface SharedNotificationPayloadBase {
  organizationId?: string;
  sender?: NotificationUser;
  code?: NotificationCodeEnum; //  Used for the reminders system and probably other stuff.
  reminderSchedule?: number[];
  onlyReminder?: boolean;
  // batchVersion?: number;
  doBatch?: boolean;
  channelId?: string; // Added for chat stuff
  messageId?: string;
  // expiresAt?: Date; //  Optional Date for auto delete
}

export interface SharedSigningPayload {
  // orderId?: string;
  recipient?: NotificationUser;
  signicatOrderId?: string;
}
export interface SharedNotificationPayload
  extends SharedNotificationPayloadBase,
    BellEventPayload,
    SMSEventPayload,
    EmailEventPayload,
    SharedSigningPayload {}

export interface CombinedNotificationPayload extends SharedNotificationPayload, RecipientPayload {}

export type HelperFunc = (recipient, data) => any;

export interface EmailTags {
  Name: string;
  Value: string;
}

export interface SMSEventPayload extends SharedNotificationPayloadBase {
  subject?: string;
  message?: string;
  phone?: string;
}

// For each org in the batch we have a list of payloads by there BatchClusterType
export type OrgNotificationItems = {
  organizationId: string;
  organizationName: string;
  payloadListByTypeMap: { [key in NotificationClusterTypeEnum]?: INotification[] };
};

export interface EmailEventPayload extends EmailInfo, SharedNotificationPayloadBase {
  organizationId?: string;
  tags?: EmailTags[];
  emailWithAttachmentPayload?: EmailWithAttachmentPayload;
  isReminder?: boolean;
  hideRecipients?: boolean;
}

export class ParamsForBellTemplate {
  title: string;
  documentName?: string;
  organizationName?: string;
  inviterName?: string;
  inviteeName?: string;
  surveyName?: string;
  signerName?: string;
  emissionName?: string;
  meetingName?: string;
  url?: string;
  address?: string;
  topic?: string;
  notes?: string;
  conversationName?: string;
  numNotifs?: number;
}

export interface BellEventPayload extends SharedNotificationPayloadBase {
  due?: Date;
  formattedTime?: string;
  recipient?: NotificationUser;
  paramsForBellTemplate?: Partial<ParamsForBellTemplate>;
  email?: string;
  organizationId?: string;
  meetingId?: string;
  direntId?: string;
  signOrderId?: string;
  requestId?: string;
  taskId?: string;
}

export interface PushEventPayload {
  message: string;
  title: string;
}

export type AnyNotificationPayload = BellEventPayload | EmailEventPayload | SMSEventPayload;

export type ParamsForBatch = {
  mainHeader: string;
};

export type ParamsForCustomEmailTemplate = {
  mainHeadline?: string;
  optionalUserInput?: string;
  firstLineInBold?: string;
  bodyTexts: string[];
  bodyTextsBelowActionButton?: string[];
  callToAction?: string;
  actionButtonText?: string;
  actionButtonUrl?: string;
  subject?: string;
  //Points to a folder which should have all its files in the email
  direntId?: string;
};

export type ParamsOrgSteps = {
  orgName: string;
  actionButtonUrl: any;
  actionButtonText: any;
  callToAction: any;
};

export type ParamsBatchSteps = {
  typeName: string;
  count: number;
};

export enum EmailTypeEnum {
  // Cerberus templates
  GENERIC_EMAIL_WITH_NO_PREDEFINED_TEXTS = 'GENERIC_EMAIL_WITH_NO_PREDEFINED_TEXTS',
  MEETING_INVITE = 'MEETING_INVITE',
  MEETING_VIDEO_INVITE = 'MEETING_VIDEO_INVITE',
  MEETING_INVITE_TO_DISCUSSION = 'MEETING_INVITE_TO_DISCUSSION',
  MEETING_INVITE_TO_SIGN = 'MEETING_INVITE_TO_SIGN',
  MEETING_INVITE_TO_SIGNV2 = 'MEETING_INVITE_TO_SIGNV2',
  SHAREHOLDER_REGISTER = 'SHAREHOLDER_REGISTER',
  NORMAL_REGISTER_WITH_INVITER = 'NORMAL_REGISTER_WITH_INVITER',
  NORMAL_REGISTER_NO_INVITER = 'NORMAL_REGISTER_NO_INVITER',
  ORGANIZATION_INVITE = 'ORGANIZATION_INVITE',
  ORGANIZATION_INVITE_NO_INVITER = 'ORGANIZATION_INVITE_NO_INVITER',
  RESET_REGISTER = 'RESET_REGISTER',
  TRIAL_REGISTER = 'TRIAL_REGISTER',
  TRIAL_EXISTING = 'TRIAL_EXISTING',
  TRANSFER_INVITE = 'TRANSFER_INVITE',
  TRANSFER_EXISTING_INVITE = 'TRANSFER_EXISTING_INVITE',
  // EMAIL_SECRET_CLICK = 'EMAIL_SECRET_CLICK',
  // SUPPORT_2FA_RECOVERY = 'SUPPORT_2FA_RECOVERY',
}

// Index fileds dor reminder records.
