import { Role } from '@orgbrain/roles';

export const textForRoles: {
  [key in Role]: { [org: string]: { en: string; nb: string; sv: string } };
} = {
  // ROLES orgbrain-db/libs/roles/src/lib/roles.ts BEGIN
  CEO: {
    default: {
      en: 'CEO',
      nb: 'Daglig leder',
      sv: 'CEO',
    },
  },
  CHAIR: {
    default: {
      en: 'Chair',
      nb: 'Styrets leder',
      sv: 'Ordförande',
    },
    SUBGR: {
      en: 'Chair',
      nb: 'Leder',
      sv: 'Ordförande',
    },
    'SUBGR-DD': {
      en: 'Chair',
      nb: 'Leder',
      sv: 'Ordförande',
    },
  },
  ONBOARD_SUPPORT: {
    default: {
      en: 'Onboard support',
      nb: 'Onboard support',
      sv: 'Onboard support',
    },
  },

  ORGBRAIN_SUPPORT: {
    default: {
      en: 'Orgbrain support',
      nb: 'Orgbrain support',
      sv: 'Orgbrain support',
    },
  },
  BOARDMEMBER: {
    default: {
      en: 'Board member',
      nb: 'Styremedlem',
      sv: 'Styrelsemedlem',
    },
    SUBGR: {
      en: 'Member',
      nb: 'Medlem',
      sv: 'Medlem',
    },
    'SUBGR-DD': {
      en: 'Member',
      nb: 'Medlem',
      sv: 'Medlem',
    },
  },
  SHAREHOLDER: {
    default: {
      en: 'Investor',
      nb: 'Investor',
      sv: 'Investerare',
    },
    fliAlike: {
      en: 'Inactive member',
      nb: 'Inaktivt medlem',
      sv: 'Inaktiv medlem',
    },
    BRL: {
      en: 'Unit investor',
      nb: 'Andelsinvestor',
      sv: 'Enhetsinvesterare',
    },
    ESEK: {
      en: 'Section investor',
      nb: 'Seksjonsinvestor',
      sv: 'Sektionsinvesterare',
    },
  },
  OWNER: {
    default: {
      en: 'Owner',
      nb: 'Eier',
      sv: 'Ägare',
    },
    fliAlike: {
      en: 'Member',
      nb: 'Medlem',
      sv: 'Medlem',
    },
    BRL: {
      en: 'Unit holder',
      nb: 'Andelseier',
      sv: 'Andelsägare',
    },
    ESEK: {
      en: 'Section owner',
      nb: 'Seksjonseier',
      sv: 'Sektionsägare',
    },
  },

  SHAREHOLDER_PROXY: {
    default: {
      en: 'Shareholder proxy',
      nb: 'Aksjeeierfullmektig',
      sv: 'Andelsägarens proxy',
    },
    fliAlike: {
      en: 'Proxy',
      nb: 'Fullmektig',
      sv: 'Fullmakt',
    },
    BRL: {
      en: 'Unitholder proxy',
      nb: 'Andelseierfullmektig',
      sv: 'Enhetsägarens fullmakt',
    },
    ESEK: {
      en: 'Section owner proxy',
      nb: 'Seksjonseierfullmektig',
      sv: 'Sektionsägarens fullmakt',
    },
  },
  ADMIN: {
    default: {
      en: 'Administrator',
      nb: 'Administrator',
      sv: 'Administratör',
    },
  },
  USER_ADMIN: {
    default: {
      en: 'User admin',
      nb: 'Bruker-admin',
      sv: 'Användaradministratör',
    },
  },
  DEPUTYCHAIR: {
    default: {
      en: 'Deputy chair',
      nb: 'Styrets nestleder',
      sv: 'Vice ordförande',
    },
    SUBGR: {
      en: 'Deputy chair',
      nb: 'Nestleder',
      sv: 'Vice ordförande',
    },
    'SUBGR-DD': {
      en: 'Deputy chair',
      nb: 'Nestleder',
      sv: 'Vice ordförande',
    },
  },
  DEPUTYMEMBER: {
    default: {
      en: 'Deputy member',
      nb: 'Varamedlem',
      sv: 'Biträdande ledamot',
    },
  },
  BOARDSECRETARY: {
    default: {
      en: 'Board secretary',
      nb: 'Styresekretær',
      sv: 'Styrelsens sekreterare',
    },
  },
  CONTACTPERSON: {
    default: {
      en: 'Contact person',
      nb: 'Kontaktperson',
      sv: 'Kontaktperson',
    },
  },
  OBSERVER: {
    default: {
      en: 'Observer',
      nb: 'Observatør',
      sv: 'Observatör',
    },
  },
  AUDITOR: {
    default: {
      en: 'Auditor',
      nb: 'Revisor',
      sv: 'Revisor',
    },
  },
  BUYER: {
    default: {
      en: 'Buyer',
      nb: 'Kjøper-innsyn',
      sv: 'Kjøper',
    },
  },
  SCHOOL: {
    default: {
      en: 'Student',
      nb: 'Student',
      sv: 'Studerande',
    },
  },
  SELLER: {
    default: {
      en: 'Seller',
      nb: 'Selger',
      sv: 'Seller',
    },
  },
  SELLER_ADMIN: {
    default: {
      en: 'Seller admin',
      nb: 'Selger-admin',
      sv: 'Seller admin',
    },
  },
  BUYER_ADMIN: {
    default: {
      en: 'Buyer admin',
      nb: 'Kjøper-admin',
      sv: 'Kjøper admin',
    },
  },
  [Role.BOARDMATCH_ADMIN]: {
    default: {
      en: 'Boardmatch Org admin',
      nb: 'Styrematch org admin',
      sv: 'Styrematch org admin',
    },
  },

  ROLE_1: {
    default: {
      en: 'Role 1',
      nb: 'Role 1',
      sv: 'Role 1',
    },
  },
  ROLE_2: {
    default: {
      en: 'Role 2',
      nb: 'Role 2',
      sv: 'Role 2',
    },
  },
  ROLE_3: {
    default: {
      en: 'Role 3',
      nb: 'Role 3',
      sv: 'Role 3',
    },
  },
  ROLE_4: {
    default: {
      en: 'Role 4',
      nb: 'Role 4',
      sv: 'Role 4',
    },
  },
  ROLE_5: {
    default: {
      en: 'Role 5',
      nb: 'Role 5',
      sv: 'Role 5',
    },
  },
  ROLE_6: {
    default: {
      en: 'Role 6',
      nb: 'Role 6',
      sv: 'Role 6',
    },
  },

  [Role.CASHIER]: {
    default: {
      en: 'CASHIER',
      nb: 'CASHIER',
      sv: 'CASHIER',
    },
  },
  // [Role.CONTROL_COMMITTEE_CHAIR]: {
  //   default: {
  //     en: 'CONTROL_COMMITTEE_CHAIR',
  //     nb: 'CONTROL_COMMITTEE_CHAIR',
  //     sv: 'CONTROL_COMMITTEE_CHAIR',
  //   },
  // },
  // [Role.NOMINATION_COMMITTEE_CHAIR]: {
  //   default: {
  //     en: 'NOMINATION_COMMITTEE_CHAIR',
  //     nb: 'NOMINATION_COMMITTEE_CHAIR',
  //     sv: 'NOMINATION_COMMITTEE_CHAIR',
  //   },
  // },
  [Role.POLICE_CERTIFICATE_RESPONSIBLE]: {
    default: {
      en: 'POLICE_CERTIFICATE_RESPONSIBLE',
      nb: 'POLICE_CERTIFICATE_RESPONSIBLE',
      sv: 'POLICE_CERTIFICATE_RESPONSIBLE',
    },
  },
  [Role.YOUTHSPORTS_MANAGER]: {
    default: {
      en: 'YOUTHSPORTS_MANAGER',
      nb: 'YOUTHSPORTS_MANAGER',
      sv: 'YOUTHSPORTS_MANAGER',
    },
  },
  [Role.COLLABORATOR]: {
    default: {
      en: 'Collaborator',
      nb: 'Medarbeider',
      sv: 'Medarbetare',
    },
  },
};
